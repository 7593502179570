<template>
    <div class="findPwd">
        <el-row type="flex" class="findPwd_box">
            <el-col :span="4"></el-col>
            <el-col :span="16">
                <el-card>
                    <div class="steps"> 
                        <div class="hor" :style="{'width': size+'%'}"></div>
                        <div class="fl" v-for="(item,index) in datamunber" :key="index" :style="{'left': item.left+'%'}" :class="item.logo === true ?'active':''">
                            <i>{{item.number}}</i>
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                    <el-form ref="userForm" :model="userForm" label-width="80px">
                        <div v-show="this.currentIndex===0">
                            <el-form-item label="手机号：" >
                                <el-input v-model="userForm.userPhone" placeholder="请输入手机号" style="width:295px;"></el-input>
                            </el-form-item>
                            <el-form-item label="验证码：">
                                <el-input v-model="userForm.phoneCode" placeholder="请输入验证码" style="width:200px;"></el-input>
                                <img src="@/assets/image/u218.png" alt="">
                            </el-form-item>
                        </div>
                        <div v-show="this.currentIndex===1">
                            <div class="findPwd_infor">已向您的手机号 <span>15259433207</span> 发送验证码，请输入：</div>
                            <el-form-item label="验证码：">
                                <el-input v-model="userForm.userCode" placeholder="请输入验证码" style="width:200px;"></el-input>
                                <img src="@/assets/image/u218.png" alt="">
                            </el-form-item>
                        </div>
                        <div v-show="this.currentIndex===2">
                            <el-form-item label="新密码：" >
                                <el-input v-model="userForm.newPaswd" placeholder="再次输入密码"></el-input>
                            </el-form-item>
                            <el-form-item label="再次输入密码：">
                                <el-input v-model="userForm.againPaswd" placeholder="请输入验证码"></el-input>
                            </el-form-item>
                        </div>
                        <div v-show="this.currentIndex===3">
                            <div style="font-size:20px;">密码设置成功，马上登录吧~</div>
                        </div>
                        <!-- <el-form-item>
                            <div style="color:red;">{{this.remind}}</div>
                        </el-form-item> -->
                        <el-form-item style="margin-top:45px;">
                            <el-button type="warning" class="nextStep" @click="nextStep()">{{this.findText}}</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
            <el-col :span="4"></el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data(){
        return{
            currentIndex:0,
            munberIndex:0,
            size:25,
            findText:'下一步',
            remind:'未填写账号',
            userForm:{
                userPhone:'',
                phoneCode:'',
                userCode:'',
                newPaswd:'',
                againPaswd:''
            },
            datamunber: [
                {
                    number:1,
                    name: '填写账号',
                    left:11,
                    logo:true
                },
                {
                    number:2,
                    name: '验证身份',
                    left:35,
                    logo:false
                },
                {
                    number:3,
                    name: '验证身份',
                    left:60,
                    logo:false
                },
                {
                    number:4,
                    name: '完成',
                    left:84,
                    logo:false
                }
            ]
        }
    },
    methods:{
        nextStep(){
            let that = this
            if(this.currentIndex<this.datamunber.length-1){
                if(that.userForm.userPhone!='' && that.userForm.phoneCode!=''){
                    that.currentIndex=1
                    that.size=50
                    if(that.userForm.userCode!=''){
                        that.currentIndex=2
                        that.size=75
                        if(that.userForm.newPaswd!='' && that.userForm.againPaswd!=''){
                            that.currentIndex=3
                            that.size=100
                            that.findText='回到首页'
                            if(that.currentIndex==3){
                                that.currentIndex=4
                                
                            }
                        }
                    }
                } 
                
                this.datamunber.map((item,index)=>{
                    if(index===that.currentIndex){
                        item.logo=true
                    }
                })
            }
            if(that.currentIndex===4){
                this.$router.replace({ name: 'Login' })
            }
        }
    }
}
</script>

<style lang="scss">
    .findPwd_box{
        width: 100%;
        margin-top: 26px;
        .el-card__body {
            padding: 75px 200px;
        }
        .steps{
            position: relative;
            height: 8px;
            background: #cecece;
            border-radius: 5px;
            font-size: 14px;
            div{
                position: absolute;
                top: -10px;
            }
            .hor{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                border-radius: 5px;
                background: #CD9F49;
            }
            .fl{
                float: left;
            }
            i{
                display: block;
                width: 30px;
                height: 30px;
                font-size: 14px;
                font-weight: bold;
                color: #fff;
                border-radius: 50%;
                background: #cecece;
                text-align: center;
                line-height: 30px;
            }
            span{
                display: block;
                width: 100px;
                text-align: center;
                margin-left: -35px;
                margin-top: 5px;
                color: #cecece;
            }
            .active i {
                background: #CD9F49;
            }
            .active span {
                color: #CD9F49;
            }
        }
        .el-form{
            width: 450px;
            text-align: center;
            margin: 100px auto;
        }
        .el-form-item__content{
            display: flex ;
        }
        .findPwd_infor{
            font-size: 18px;
            margin-bottom: 35px;
            span{
                color:#CD9F49;
            }
        }
        .nextStep{
            width: 254px;
            height: 44px;
        }
    }
</style>